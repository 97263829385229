import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Link`}</h1>
    <p>{`A Link takes the user to another page
or to a specific location on a page.`}</p>
    <ComponentPreview componentName="link--default-story" hasReact hasAngular hasHTML titleAttr="Link Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use a Link when you want the user to
navigate to another page, a specific
location on a page, or to download a document.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Link text should clearly describe where
the Link will take the user. It must answer,
“what will the user get when they interact
with this?”`}</li>
      <li parentName="ul">{`Do not use ambiguous Link text like “Click Here”.`}</li>
      <li parentName="ul">{`Don’t use a Link if you aren’t navigating
somewhere or downloading a document unless
the proper accessibility considerations are met.`}</li>
      <li parentName="ul">{`Links should primarily be part of a sentence
or near other text content.`}</li>
      <li parentName="ul">{`If used as a standalone call to action,
use first-person pronouns. For example: Use "Download My
Approval Letter," instead of "Download Your Approval Letter."`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Default Link`}</h3>
    <p>{`Default Link is the standard Link style and
should be used for most hyperlinks.`}</p>
    <ComponentPreview componentName="link--default-story" hasReact hasAngular hasHTML titleAttr="Default Link Example" mdxType="ComponentPreview" />
    <h3>{`Simple Link`}</h3>
    <p>{`A more subtle Link style which looks more like regular body copy.
Use Simple Links in navigation, or in the header and footer of a page,
where context implies an ability to interact with the text.`}</p>
    <ComponentPreview componentName="link--simple" hasReact hasAngular hasHTML titleAttr="Simple Link Example" mdxType="ComponentPreview" />
    <h3>{`Light Link`}</h3>
    <p>{`A link style for dark backgrounds.
Light links are mainly used in `}<a parentName="p" {...{
        "href": "/using-spark/components/footer"
      }}>{`Footer`}</a>{`.`}</p>
    <ComponentPreview componentName="link--light" hasReact hasAngular hasHTML titleAttr="Light Link Example" mdxType="ComponentPreview" />
    <h3>{`Icon with Text Link`}</h3>
    <p>{`This style combines an Icon with text.
This is most useful in menus or standalone
text and shouldn’t be used in a sentence.`}</p>
    <ComponentPreview componentName="link--icon-with-text-link" hasReact hasAngular hasHTML titleAttr="Icon with Text Link Example" mdxType="ComponentPreview" />
    <h3>{`Disabled`}</h3>
    <p>{`Represents a Link that’s not currently available for interaction.
Using this variation helps screen readers and other assistive technologies understand that the Link can’t be interacted with.`}</p>
    <ComponentPreview componentName="link--disabled" hasReact hasAngular hasHTML titleAttr="Disabled Link Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`A Link must contain text.`}</li>
      <li parentName="ul">{`A Link may contain an icon.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Avoid using Link to perform actions other than
navigation or accessing external resources, like
a file download. If you do, you must provide the
appropriate `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.1/"
        }}>{`accessibility controls`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      